@import "styles/functions.scss";
@import "styles/mixins.scss";
@import "./styles.module.scss";

/* #region menuItem*/
.center {
  @include center;
}
.menuItem {
  display: flex;
  flex-flow: column;
  transition: all time(default);
  margin-bottom: 15px;
  // color: $light-textColor;
  // & path {
  //   stroke: $light-textColor;
  // }
  & a {
    color: colorFromRgb(secondary-main);
  }
}

.menuItemContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 35px;
  padding: $sidebarPadding;
  @include padding-right(15px !important);
  padding-top: 0;

  @media (max-width: $screenThreshold) {
    overflow-y: unset !important;
  }

  a {
    &:nth-child(2) {
      width: 100%;
    }
  }
}
.selectedItem {
  @include selectedItem;
}
.itemLabel {
  color: colorFromRgb(secondary-main);
}

.menu_container__min {
  margin-top: 20px !important;
}

.hiddenScroll {
  overflow-y: hidden;
}
.hideText {
  @include hideText;
}
.menuItemText {
  width: 100%;
  @include text();
  // @include margin-left( 5px;
  font-weight: 500;
}
.rootItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 5px;
  @include disable-select;
  width: 100%;
  font-weight: 500;
}
.arrow {
  @include margin-left(auto);
  & svg {
    transition: all time(default);
    // transform: rotate(-90deg);
    @include rotate(-90);
    path {
      stroke: colorFromRgb(secondary-main);
    }
  }
}
.rotateDown {
  & svg {
    transform: rotate(0deg) !important;
    // @include rotate(0);
  }
}
.itemIcon {
  padding-bottom: 1px;
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
.menuItemIconContainer {
  margin-top: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* #endregion */
