@import "styles/functions.scss";
@import "styles/mixins.scss";

.inputContainer {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  border-radius: space(radius);
  border: 2px solid transparent;
  padding: space(pd-y) space(pd-x);

  &[data-size="small"] {
    max-height: space(height-small);
    min-height: space(height-small);
  }

  &[data-size="normal"] {
    max-height: space(height-normal);
    min-height: space(height-normal);
  }

  &[data-size="big"] {
    max-height: space(height-big);
    min-height: space(height-big);
  }

  &[data-variant="search"] {
    background-color: rgba(color(gray-text), 0.1);

    border: 2px solid transparent;
    color: rgba(color(gray-text), 0.6);
    & > .icon[data-variant="bold"] > path {
      fill: rgba(color(gray-text), 0.6);
      transition: time(transition-hover);
    }

    & > .icon[data-variant="linear"] > path {
      stroke: rgba(color(gray-text), 0.6);
      transition: time(transition-hover);
    }

    &:hover {
      border-color: rgba(color(gray-text), 0.6);
    }

    & > .input {
      background-color: transparent;
    }
  }

  &[data-variant="textnumber"] {
    background-color: colorFromRgb(secondary-background);

    &[data-validation="success"] {
      border-color: colorFromRgb(success);
      transition: time(default);

      .inputTailIcon {
        &[data-variant="bold"] > path {
          fill: colorFromRgb(success);
        }

        &[data-variant="linear"] > path {
          stroke: colorFromRgb(success);
        }
        transition: time(default);
      }
    }

    &[data-validation="error"] {
      border-color: colorFromRgb(danger);
      transition: time(default);

      .inputTailIcon {
        &[data-variant="bold"] > path {
          fill: colorFromRgb(danger);
        }

        &[data-variant="linear"] > path {
          stroke: colorFromRgb(danger);
        }
        transition: time(default);
      }
    }

    &[data-validation="warning"] {
      border-color: colorFromRgb(warning);
      transition: time(default);

      .inputTailIcon {
        &[data-variant="bold"] > path {
          fill: colorFromRgb(warning);
        }

        &[data-variant="linear"] > path {
          stroke: colorFromRgb(warning);
        }
        transition: time(default);
      }
    }

    &[data-validation="none"]:focus-within {
      border-color: rgba(color(primary-main), 0.8);
    }

    &[data-disabled="true"] {
      background-color: rgba(color(gray-text), 0.2);
      border: 2px solid transparent;
      color: rgba(color(gray-text), 0.6);
      & > .icon[data-variant="bold"] > path {
        fill: colorFromRgb(gray-text);
        transition: time(transition-hover);
      }

      & > .icon[data-variant="linear"] > path {
        stroke: colorFromRgb(gray-text);
        transition: time(transition-hover);
      }
    }
  }

  transition: time(default);
}
.inputTailIcon {
  @include margin-left(space(mg-between));
  transition: time(default);
  width: 25px;
  &[data-variant="bold"] > path {
    fill: colorFromRgb(secondary-main);
  }

  &[data-variant="linear"] > path {
    stroke: colorFromRgb(secondary-main);
  }
}
.rotate {
  transform: rotate(180deg);
}
.inputHeadIcon {
  @include margin-right(space(mg-between));

  &[data-variant="bold"] > path {
    fill: rgba(color(gray-text), 0.6);
  }

  &[data-variant="linear"] > path {
    stroke: rgba(color(gray-text), 0.6);
  }
}

.input {
  border: none;
  width: 100%;
  color: rgba(color(gray-text), 1);
  background-color: transparent;
  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    color: rgba(color(gray-text), 0.6);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[mask-type="number"] {
    -moz-appearance: textfield;
  }
}

.inputTailIcon {
  @include margin-left(space(mg-between));
  transition: time(default);
}

.type {
  @include margin-left(10px);
  font-weight: 600;
  font-size: 14px;
  color: colorFromRgb(secondary-main);
  flex-shrink: 0;
}

.typeDisable {
  color: colorFromRgb(gray-text);
}
