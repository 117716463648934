@import "styles/functions.scss";
@import "styles/mixins.scss";
@import "./styles.module.scss";

/* #region Theme Changer */
.themeChangerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: space(pd-x);
}

.darkModeSwitch {
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  background-color: colorFromRgba(gray-text, 20);
  border-radius: 30px;
  cursor: pointer;
}

.switchOption {
  display: flex;
  background-color: transparent;
  color: colorFromRgb(gray-text);
  font-size: 14px;
  border-radius: 30px;
  padding: 5px 10px;
  transition: all 100ms ease-in;
  font-weight: 500;

  svg {
    width: 18px;
    height: 18px;
  }

  path {
    fill: colorFromRgb(gray-text);
  }

  span {
    @include margin-left(6px);
  }
}

.switchOptionActive {
  background-color: colorFromRgb(primary-background);
  color: colorFromRgb(secondary-main);

  path {
    fill: colorFromRgb(secondary-main);
    fill-opacity: 1;
  }

  span {
    @include margin-left(6px);
  }
}

.paletteChanger {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.paletterChangerWrapped {
  display: flex;
  gap: 8px;
  justify-content: center;
  // align-items: center;
  flex-wrap: wrap;
  transition: all 900ms ease-in;
}

.colorOption {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: all 50ms ease-in;
  cursor: pointer;
}

.colorOptionActive {
  background-clip: content-box;
  padding: 2px;
  border: 2px solid colorFromRgb(secondary-main);
}

.paletteBlue {
  background-color: colorFromRgb(royal-blue);
  // margin: 0 3px 0 0;
}
.paletteShamrock {
  // margin: 0 3px;
  background-color: colorFromRgb(shamrock);
}
.paletteYellow {
  // margin: 0 0 0 3px;
  background-color: colorFromRgb(warning);
}
/* #endregion */
