@import "styles/functions.scss";

.sideBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  gap: 30px;
  width: 80px;
  flex: 0 0 auto;
  @media (max-width: 1335px) {
    width: 80px;
  }

  @media (max-width: 370px) {
    width: 60px;
  }

  &__close {
    display: none;
  }

  background: colorFromRgb(secondary-background);
  border-left: 1px solid colorFromRgba(gray-text, 30);
  height: 100%;

  &__item {
    position: relative;
    z-index: 99999;

    svg {
      cursor: pointer;
      width: 26px;

      @media (max-width: 430px) {
        width: 18px;
      }
      @media (max-height: 850px) {
        width: 18px;
      }
      path {
        fill: colorFromRgb(secondary-main);
      }
    }

    &:hover .sideBar__label {
      opacity: 1;
      visibility: visible;
    }
  }

  &__in {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 700;
    font-size: 11px;
    text-align: center;
    padding: 0 5px;
    color: colorFromRgb(secondary-main);

    @media (max-width: 430px) {
      font-size: 8px;
    }
    @media (max-height: 850px) {
      font-size: 8px;
    }
  }

  &__label {
    position: absolute;
    right: 50px;
    top: -5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 99999;

    svg {
      width: 20px;
      height: 20px;
      transform: translateX(-7px);
      path {
        fill: colorFromRgb(swamp);
      }
    }
  }

  &__title {
    background-color: colorFromRgb(swamp);
    width: max-content;
    border-radius: 8px;
    padding: 12px;
    font-weight: 600;
    font-size: 14px;
    color: colorFromRgb(light-text);
  }
}

.primary {
  svg {
    path {
      fill: colorFromRgb(primary-main);
    }
  }
}

.doc {
  gap: 20px;
  justify-content: space-around;

  @media (max-width: 430px) {
    gap: 10px;
  }
  @media (max-height: 850px) {
    gap: 10px;
  }
  @media (max-height: 700px) {
    gap: 6px;
  }

  @media (max-height: 650px) {
    overflow-y: auto;
    padding-top: 50%;
  }

  @media (max-height: 500px) {
    padding-top: 75%;
  }

  @media (max-height: 400px) {
    padding-top: 85%;
  }
}
