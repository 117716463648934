@import "styles/functions.scss";

.imageCard {
  width: 100%;
  padding: 2px;
  background-color: transparent;
  border: 4px solid transparent;
  transition: all 0.3s ease-in-out;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    border-radius: 7px;
    width: 100%;
    object-position: center;
  }

  &__date {
    font-size: 14px;
    color: colorFromRgb(gray-text);
    font-weight: 500;
    padding: 10px;
  }

  &:hover {
    border: 4px solid colorFromRgb(primary-main);
  }
}

.imgPlace {
  height: calc(100% - 120px);

  :global .iiz__btn {
    display: none;
  }
  video {
    height: 100%;
  }
}

.heightCut {
  height: calc(100% - 40px);
}

.image {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  text-align: center;

  img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: center;
  }
}

.imgOverflow {
  img {
    //margin-top: 100%;
  }
}

.images {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__place {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto;
    padding: 20px;
    gap: 20px;
    height: fit-content;
  }

  &__noData {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: colorFromRgb(gray-text);
  }

  &__container {
    background: colorFromRgba(gray-text, 10);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.ImageModal {
  position: fixed;

  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  //width: 861px;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  max-width: calc(100vw - 220px);
  max-height: 85vh;

  background: colorFromRgb(secondary-background);
  box-shadow: 0 4px 20px rgba(0, 23, 31, 0.25);
  border-radius: 5px;
  transform: translate(-50%, -50%);
  z-index: 9999999999;

  &__modalButtons {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-direction: row-reverse;
  }

  &__cross {
    outline: none;
    border: none;
    background-color: transparent;

    svg {
      width: 20px;
      height: 20px;
      &[data-variant="bold"] > path {
        fill: colorFromRgb(secondary-main);
      }

      &[data-variant="linear"] > path {
        stroke: colorFromRgb(secondary-main);
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__btn {
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;

    background: colorFromRgba(gray-text, 10);
    border-radius: 5px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: colorFromRgba(gray-text, 30);
    }

    svg {
      path {
        stroke: colorFromRgb(primary-main);
      }
    }
  }
}

.imageItem {
  display: flex;
  flex-direction: column;
  height: fit-content;

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    gap: 10px;
    width: 100%;

    height: 68px;

    background: colorFromRgb(secondary-background);
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: colorFromRgba(gray-text, 30);
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }

  &__number {
    color: colorFromRgb(primary-main);
  }

  &__icon {
    transition: all 0.3s ease-in-out;

    svg {
      path {
        stroke: colorFromRgb(secondary-main);
      }
    }
  }

  &__content {
    display: flex;
    overflow: hidden;
    transition: height 0.5s ease-in-out, width 0.5s ease-in-out;
    z-index: 9999999999;
  }

  &__btns {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
  &__button {
    width: 100%;
    max-width: unset;
  }

  &__contentInside {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    gap: 20px;
  }
}

.iframe {
  height: 100%;
  min-height: 400px;
}

.posRelative {
  position: relative;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.iframeParent {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px ;
  margin: 0 auto;

  &__link {
    padding: 6px 8px;
    cursor: pointer;
    color: colorFromRgb(primary-main);
    transition: all 0.3s ease-out;

    &:hover {
      color: colorFromRgba(primary-main, 80);
    }
  }
}

.pdfRenderer {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  overflow-x: auto;
  margin: 10px;


}

.pdfRendererFull {
  width: 100%;
}

.rotate {
  transform: rotateZ(90deg);
}
