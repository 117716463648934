@import "styles/functions.scss";
@import "styles/mixins.scss";
@import "./styles.module.scss";

/* #region sidebar base */

.sidebarContainer {
  @media print {
    display: none !important;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  // left: 0;
  @include left(0);
  width: getSidebarWidth();
  height: 100vh;
  border-right: 1px solid #bcbcbc80;
  transition: width time(default);
  // overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: colorFromRgb(secondary-background);
  z-index: 1000;
  @media (max-width: getScreenThreshold()) {
    height: calc(100vh - space(mobile-header-height));
    border-top: 1px solid #bcbcbc80;
    position: fixed;
    top: space(mobile-header-height);
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: block;
    overflow-y: auto;

    border-right: 0 !important;
  }
}
.sideIn {
  width: calc(getSidebarMinWidth());
  @media (max-width: getScreenThreshold()) {
    // left: -100px;
    @include left(-100px);
    width: 0;
  }
}
.center {
  // margin-left: 18px;
  display: flex;
  align-items: center;
}
.sidebar_separator__text {
  font-weight: 600;
  color: colorFromRgb(gray-text);
  padding: 10px 0 8px;
  flex-direction: column;
}
.menuItemContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 35px;
  padding: $sidebarPadding;
  @include padding-right(15px !important);
  padding-top: 0;

  @media (max-width: $screenThreshold) {
    overflow-y: unset !important;
  }

  a {
    &:nth-child(2) {
      width: 100%;
    }
  }
}

/* #endregion */
