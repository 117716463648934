@import "styles/functions.scss";
@import "styles/mixins.scss";

.pageContainer {
  min-height: 100vh;
  @include flex-center();
}

.pageContent {
  align-items: center;
  @include flex-column();
}

.pageText {
  margin-top: 0.3rem;
  color: colorFromRgb(secondary-main);
  line-height: 2;
  font-size: 16px;
  font-weight: 700;
}

.btnStyle {
  margin-top: 1rem;
}
