@import "styles/functions.scss";
@import "styles/mixins.scss";
@import "./styles.module.scss";

.collapse {
  border-radius: 5px;
  max-height: 0;
  overflow: hidden;
  transition-duration: time(default);
  transition-property: max-height;
  margin-top: 5px;
}

.collapse__container {
  background-color: colorFromRgb(primary-background);
  padding: 15px;
}
