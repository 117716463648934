@import "styles/functions.scss";

.vitalItem {
  display: flex;
  flex-direction: column;
  height: fit-content;

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    gap: 10px;
    width: 100%;

    height: 68px;

    background: colorFromRgb(secondary-background);
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: colorFromRgba(gray-text, 30);
  }

  &__content {
    display: flex;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }

  &__contentInside {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    gap: 20px;
  }

  &__contentOpen {
  }

  &__rows {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }

  &__resultText {
    font-weight: 600;
    font-size: 15px;
    color: colorFromRgb(primary-main);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    svg {
      path {
        stroke: colorFromRgb(primary-main);
      }
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__pastData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: colorFromRgb(gray-text);
  }

  &__icon {
    transition: all 0.3s ease-in-out;

    svg {
      path {
        stroke: colorFromRgb(secondary-main);
      }
    }
  }
  &__refresh {
    background-color: colorFromRgb(primary-main);
    border-radius: 10px;
    padding: 6px 10px;
    margin-bottom: 6px;
    color: colorFromRgb(light-text);
    svg {
      path {
        stroke: colorFromRgb(light-text);
      }
    }

    &:hover {
      background-color: colorFromRgba(primary-main, 80);
    }
  }
}

.rotate {
  transform: rotateZ(90deg);
}

.vitalSign {
  display: flex;
  flex-direction: column;

  height: 100%;

  &__place {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    background-color: colorFromRgba(gray-text, 10);
  }

  &__cursor {
    cursor: pointer;
  }
}
