@import "styles/functions.scss";

:global .MuiAccordion-root {
  background-color: transparent !important;
  padding: 0 !important;
}

:global .MuiAccordionSummary-content {
  margin: 0 !important;
}
:global .MuiPaper-root {
  margin: 0 !important;
}

* {
  [class$="-MuiButtonBase-root-MuiAccordionSummary-root"] {
    padding: 0 !important;
  }

  [class$="MuiPaper-root-MuiAccordion-root.Mui-expanded"] {
    margin: 0 !important;
  }

  [class$="-MuiAccordionDetails-root"] {
    padding: 0 !important;
  }

  [class$="-MuiPaper-root-MuiAccordion-root"] {
    position: unset !important;
  }
}

.messageIcon {
  position: relative;

  &__red {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 17px;
    right: 1px;
    background-color: colorFromRgb(danger);
    border-radius: 500px;
    animation: hideAnim 1.2s ease-in;
    animation-iteration-count: infinite;
  }
}

@keyframes hideAnim {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

$screenThreshold: 992px;

.videoVisit {
  z-index: 10;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border: 1px solid colorFromRgba(gray-text, 30);
  overflow: hidden;
  @media (min-width: $screenThreshold) {
    left: 100px;
    width: calc(100% - 100px);
    top: 0;
  }
  @media (max-width: $screenThreshold) {
    height: calc(100% - 70px);
  }
  // @media (max-width: $screenThreshold) {
  //   top: 70px;
  // }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
  }

  &__start {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    path {
      fill: colorFromRgb(primary-main);
    }
  }

  &__contents {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__videoPlaces {
    flex-grow: 1;
    height: 100%;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 30px;
    grid-template-rows: auto;
    background-color: colorFromRgb(primary-background);
    overflow-y: auto;

    @media (max-height: 400px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    @media (max-width: 700px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    @media (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }

  &__top {
    display: flex;
  }

  &__middle {
    display: flex;
    height: 100%;
  }
}

.side {
  width: 0;
  transition: all 0.3s ease-in-out;
  background-color: colorFromRgb(light-text);
  overflow: hidden;
  display: flex;
  z-index: 90;
  @media (max-width: 1380px) {
    position: absolute;
    top: 0;
    height: 100%;
    right: 80px;
  }

  @media (max-width: 370px) {
    right: 60px;
  }

  &__sideInside {
    border-left: 1px solid colorFromRgba(gray-text, 30);
    opacity: 0;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: colorFromRgb(secondary-background);
  }

  &__sideTop {
    height: 110px;
    flex: 0 0 auto;
    background-color: colorFromRgb(secondary-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    svg {
      [data-variant="bold"] > path {
        fill: colorFromRgb(gray-text);
      }

      [data-variant="linear"] > path {
        fill: colorFromRgb(gray-text);
      }
    }

    border-bottom: 1px solid colorFromRgba(gray-text, 30);
  }

  &__sideTitle {
    font-weight: 700;
    font-size: 20px;
    color: colorFromRgb(secondary-main);
  }

  &__sideText {
    font-weight: 600;
    font-size: 14px;

    color: colorFromRgb(primary-main);
  }

  &__sideLeft {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  &__sideBtn {
    cursor: pointer;
    padding: 20px 10px;

    svg {
      path {
        stroke: colorFromRgb(secondary-main);
      }
    }
  }
}

.sideOpen {
  width: 420px;
  @media (max-width: 500px) {
    width: calc(100% - 80px);
  }
  @media (max-width: 370px) {
    width: calc(100% - 60px);
  }
}

.sideVisible {
  opacity: 1;
}

.p0 {
  padding: 0;
}

.multi {
  height: 100%;
  width: 100%;
}

.one {
  height: 100%;
}

.firstPage {
  display: flex;
  flex-direction: column;
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: colorFromRgb(primary-background);
  padding: 40px;
  overflow-y: auto;
  gap: 20px;

  @media (max-width: 720px) {
    align-items: center;
    padding: 20px;
  }
  @media (max-height: 720px) {
    padding: 20px;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    height: 60px;
  }

  &__levels {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  &__level {
    width: 7px;
    height: 40px;
    background: #d9d9d9;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
  }

  &__levelActive {
    background: colorFromRgb(primary-main);
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    color: colorFromRgb(gray-text);
  }

  &__justify {
    text-align: justify;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 20px;
    width: 100%;
    margin: auto 0;
    gap: 40px;
  }

  &__form {
    max-width: 430px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    input[type="range"] {
      overflow: hidden;
      -webkit-appearance: none;
      color: colorFromRgb(secondary-background);
      border-radius: 25px;
      width: 420px;
      align-self: center;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: colorFromRgb(primary-main);
      margin-top: -1px;
    }

    input[type="range"]::-webkit-slider-thumb {
      width: 10px;
      height: 10px;

      -webkit-appearance: none;
      cursor: pointer;
      background-color: colorFromRgb(swamp);
      box-shadow: -420px 0 0 420px colorFromRgb(primary-main);
    }
  }

  &__inputTitle {
    font-weight: 700;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }

  &__iconPlace {
    position: relative;
    svg {
      width: 100px;
      height: 100px;
    }

    @media (max-height: 760px) {
      svg {
        width: 60px;
        height: 60px;
      }
    }
  }

  &__iconAbove {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    width: 48px;
    svg {
      width: 48px;
      height: 48px;
    }

    @media (max-height: 760px) {
      width: 28.8px;
      svg {
        width: 28.8px;
        height: 48px;
      }
    }

    rect {
      fill: colorFromRgb(primary-background);
    }
  }

  &__head {
    [data-variant="bold"] > path {
      fill: colorFromRgb(primary-main);
    }

    [data-variant="linear"] > path {
      fill: colorFromRgb(primary-main);
    }

    font-weight: 700;
    font-size: 36px;
    color: colorFromRgb(secondary-main);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;

    @media (max-height: 780px) {
      font-size: 28px;
    }
  }

  &__videoPart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    max-width: 640px;
    width: 100%;
    aspect-ratio: 1;
  }

  &__video {
    display: flex;
    background-color: colorFromRgb(secondary-main);
    position: relative;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1;
  }

  &__vidInside {
    max-width: 640px;
    width: 100%;
    height: 480px;
  }

  &__videoContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 20px;
    background-color: colorFromRgb(swamp);
  }

  .videoBack {
    background-color: colorFromRgb(secondary-main);
  }

  &__vid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    max-width: 640px;
  }

  &__videoTitle {
    font-weight: 500;
    font-size: 24px;
    color: colorFromRgb(light-text);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &__icons {
    z-index: 200;
    justify-self: flex-end;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__sound {
    z-index: 900;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    border-radius: 500px;
    background-color: colorFromRgb(primary-main);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  &__soundO {
    width: 6px;
    height: 15px;

    background: colorFromRgb(light-text);
    border-radius: 30px;
  }

  &__soundC {
    width: 6px;
    height: 15px;

    background: colorFromRgb(light-text);
    border-radius: 30px;
  }

  &__check {
    display: flex;
    align-items: center;
    gap: 8px;

    font-weight: 500;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }

  &__button {
    font-weight: 600;
    font-size: 16px;
    color: colorFromRgb(light-text);
    max-width: unset !important;
    width: 100%;
  }

  &__bigBtn {
    width: 420px;
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    max-width: 420px;
    width: 100%;
  }

  &__join {
    font-weight: 600;
    font-size: 38px;
    color: colorFromRgb(secondary-main);
  }

  &__joinText {
    font-weight: 500;
    font-size: 24px;
    color: colorFromRgb(gray-text);
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    color: colorFromRgb(secondary-main);
  }

  &__subTitle {
    font-weight: 500;
    font-size: 16px;
    color: colorFromRgb(gray-text);
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    border-radius: 5px;
    cursor: pointer;
    gap: 10px;
    padding: 0 15px;
  }

  &__smallBtn {
    width: 200px;
  }
}

.an1 {
  animation: anim1 0.5s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.an2 {
  animation: anim2 0.5s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.white {
  background-color: colorFromRgb(secondary-background);
}

.primary {
  background-color: colorFromRgb(primary-main);

  svg {
    [data-variant="bold"] > path {
      fill: colorFromRgb(primary-main);
    }

    [data-variant="linear"] > path {
      fill: colorFromRgb(primary-main);
    }
  }
}

@keyframes anim1 {
  0% {
    height: 15px;
  }

  100% {
    height: 20px;
  }
}

@keyframes anim2 {
  0% {
    height: 25px;
  }

  100% {
    height: 15px;
  }
}

.dropdown {
  padding: 0;
  background: colorFromRgb(primary-background) !important;
  border: 1px solid colorFromRgba(gray-text, 80) !important;
  border-radius: 5px;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: colorFromRgb(secondary-main) !important;

  &__item {
    padding: 15px 20px;
    font-size: 18px !important;

    &:hover {
      background: colorFromRgba(primary-main, 10) !important;
      color: colorFromRgb(primary-main) !important;
    }
  }

  &__title {
    color: colorFromRgb(secondary-main);
    padding: 15px 20px;
    font-size: 18px !important;
  }
}

.firstBack {
  z-index: 999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: colorFromRgba(secondary-main, 5);
  top: 0;
  left: 0;
  backdrop-filter: blur(200px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  svg {
    path {
      fill: colorFromRgb(primary-main);
    }
  }

  &__title {
    color: colorFromRgb(secondary-main);
    font-weight: 700;
    font-size: 24px;
  }
}
.full {
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
