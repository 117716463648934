@import "styles/functions.scss";

.participantCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3px;

  background: colorFromRgb(secondary-background);
  border-radius: 5px;

  width: 100%;
  height: 100%;

  &__avatarContainer {
    background-color: #ffefdb;
    width: 75px;
    height: 75px;
    border-radius: 500px;
    overflow: hidden;
    flex: 0 0 auto;
  }

  &__avatar {
    width: 75px !important;
    height: 75px !important;
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;

    color: colorFromRgb(seconday-main);
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__role {
    font-weight: 500;
    font-size: 12px;
    color: colorFromRgb(gray-text);
  }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  &__icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }

  &__icon {
    cursor: pointer;

    svg {
      width: 24px;
    }
  }
}

.pos {
  path {
    stroke: colorFromRgb(primary-main);
  }
}

.neg {
  path {
    stroke: colorFromRgb(danger) !important;
  }
}

.participantContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  gap: 20px;
  background-color: colorFromRgba(gray-text, 10);
  overflow-y: auto;
  flex-grow: 1;
}

.btn {
  background-color: colorFromRgba(gray-text, 10);
  padding: 20px;
  display: flex;
  width: 100%;
}

.btnIn {
  width: 100%;
  max-width: unset;
}

.participants {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.none {
  visibility: hidden;
}
