@import "styles/functions.scss";

.statusCard {
  background-color: colorFromRgb(swamp);
  padding: 20px;

  color: colorFromRgb(light-text);
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__num {
    font-weight: 700;
    font-size: 24px;
    justify-self: flex-start;
  }

  &__condition {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    color: colorFromRgb(secondary-main);
    justify-self: flex-end;
  }

  &__range {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 15px;
  }

  &__indicator {
    width: 8px;
    height: 8px;
    background: #f74b4a;
    border: 1px solid #ffffff;
    border-radius: 200px;
  }

  &__gradient {
    height: 14px;
    background: linear-gradient(
      90deg,
      #295de1 0%,
      #47cbff 37.77%,
      #fec101 70.4%,
      #f74b4a 100%
    );
    border-radius: 21px;
  }

  &__fullWidth {
    width: 100%;
  }

  &__labelPlace {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__smallText {
    font-weight: 700;
    font-size: 12px;
  }
}

.normal {
  background-color: colorFromRgb(success);
}

.warning {
  background-color: colorFromRgb(warning);
}

.danger {
  background-color: colorFromRgb(danger);
}
