@use "../../node_modules/bootstrap/scss/bootstrap.scss";
@use "mixins" as mx;
@import "./functions.scss";
// TODO: Just import needed sections of bootstrap (Grid, Utils)
// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.cdnfonts.com/css/barlow");

$spacer: 1rem;

$spacer: 1rem;

html,
body {
  padding: 0;
  margin: 0;
  background-color: colorFromRgb(primary-background);
  font-size: 15px;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  color: colorFromRgb(secondary-main);
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

* {
  @page {
    zoom: 0.55 !important;
  }
}

#root {
  background-color: colorFromRgb(primary-background);
  min-height: 100vh;

  .Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: colorFromRgb(primary-main);
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: colorFromRgb(success);
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: colorFromRgb(warning);
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: colorFromRgb(danger);
  }
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;

  font-size: inherit;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #79899e;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #79899e;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: color(gray-main);
  -webkit-box-shadow: 0 0 0 1000px color(paper-light) inset;
  box-shadow: 0 0 0 1000px color(paper-light) inset;
  transition: background-color 5000s ease-in-out 0s;
}

// RESET of fullcalendar title
.fc-toolbar-title,
.fc-daygrid-day-number,
.fc-col-header-cell-cushion {
  color: colorFromRgb(secondary-main);
}

.fc-button-group {
  gap: 0.5em;
}

.fc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.fc-toolbar-chunk {
  display: flex;
  gap: 10px;
}

.fc-toolbar > * > :not(:first-child) {
  @include mx.margin-left(0.5em !important);
}

.fc-today-button {
  background-color: colorFromRgb(primary-main) !important;
  text-transform: capitalize !important;
}

.fc-myCustomButton-button {
  background-color: colorFromRgb(primary-main) !important;
}

.fc-prev-button,
.fc-next-button {
  background-color: colorFromRgba(gray-text, 10) !important;
  border-radius: 5px;
  padding: 5px !important;
}

.fc-icon {
  color: colorFromRgb(secondary-main) !important;
}

.fc-h-event {
  border: none !important;
}

.fc-daygrid-event {
  z-index: unset !important;
}

.fc-event-main {
  z-index: unset !important;
}

.fc-popover-body {
  overflow-y: auto;
  max-height: 250px;
  background-color: colorFromRgb(secondary-background) !important;
}

// .fc-popover-header {
//   // background-color: colorFromRgb(gray-text) !important;
// }

.tableSearchInput {
  max-height: 40px !important;
  min-height: 40px !important;

  svg {
    path {
      stroke: colorFromRgb(gray-text) !important;
    }
  }

  & > input::placeholder {
    color: colorFromRgb(gray-text);
    font-weight: 600;
  }
}

.ml-2 {
  @include mx.margin-left(0.5rem);
}

// override bootstrap margin
.MS-0 {
  @include mx.margin-left(0 !important);
}
.MS-1 {
  $var: calc($spacer * 0.25);
  @include mx.margin-left(#{$var} !important);
}
.MS-2 {
  $var: calc($spacer * 0.5);
  @include mx.margin-left(#{$var} !important);
}
.MS-3 {
  @include mx.margin-left(#{$spacer} !important);
}
.MS-4 {
  $var: calc($spacer * 1.5);
  @include mx.margin-left(#{$var} !important);
}
.MS-5 {
  $var: calc($spacer * 3);
  @include mx.margin-left(#{$var} !important);
}
.MS-auto {
  @include mx.margin-left(auto !important);
}
.ME-0 {
  @include mx.margin-right(0 !important);
}
.ME-1 {
  $var: calc($spacer * 0.25);
  @include mx.margin-right(#{$var} !important);
}
.ME-2 {
  $var: calc($spacer * 0.5);
  @include mx.margin-right(#{$var} !important);
}
.ME-3 {
  @include mx.margin-right(#{$spacer} !important);
}
.ME-4 {
  $var: calc($spacer * 1.5);
  @include mx.margin-right(#{$var} !important);
}
.ME-5 {
  $var: calc($spacer * 3);
  @include mx.margin-right(#{$var} !important);
}
.ME-auto {
  @include mx.margin-right(auto !important);
}

// override bootstrap padding
.PS-0 {
  @include mx.padding-left(0 !important);
}
.PS-1 {
  $var: calc($spacer * 0.25);
  @include mx.padding-left(#{$var} !important);
}
.PS-2 {
  $var: calc($spacer * 0.5);
  @include mx.padding-left(#{$var} !important);
}
.PS-3 {
  @include mx.padding-left(#{$spacer} !important);
}
.PS-4 {
  $var: calc($spacer * 1.5);
  @include mx.padding-left(#{$var} !important);
}
.PS-5 {
  $var: calc($spacer * 3);
  @include mx.padding-left(#{$var} !important);
}
.PS-auto {
  @include mx.padding-left(auto !important);
}
.PE-0 {
  @include mx.padding-right(0 !important);
}
.PE-1 {
  $var: calc($spacer * 0.25);
  @include mx.padding-right(#{$var} !important);
}
.PE-2 {
  $var: calc($spacer * 0.5);
  @include mx.padding-right(#{$var} !important);
}
.PE-3 {
  @include mx.padding-right(#{$spacer} !important);
}
.PE-4 {
  $var: calc($spacer * 1.5);
  @include mx.padding-right(#{$var} !important);
}
.PE-5 {
  $var: calc($spacer * 3);
  @include mx.padding-right(#{$var} !important);
}
.PE-auto {
  @include mx.padding-right(auto !important);
}
