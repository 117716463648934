@import "styles/functions.scss";

.writeAddendum {
  &__topLinks {
    font-weight: 600;
    font-size: 18px;
    color: colorFromRgb(gray-text);
  }

  &__cp {
    color: colorFromRgb(primary-main);
  }

  &__pointer {
    cursor: pointer;
  }

  &__secondary {
    font-weight: 700;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }

  &__big {
    font-weight: 700;
    font-size: 24px;
  }

  &__addendums {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__margin {
    margin-bottom: 10px;
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 100px;

    @media print {
      display: none !important;
    }
  }

  &__area {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__sign {
    border-top: 1px solid colorFromRgba(gray-text, 30);
    padding-top: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__signImage {
    width: 300px;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }

  &__alignStart {
    align-items: flex-start;
  }

  &__gray {
    white-space: pre-wrap;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: colorFromRgb(gray-text);
  }
}

.pg {
  break-inside: avoid;
}
