@import "styles/functions.scss";

.loader {
  svg {
    animation: bounce 0.8s ease-in-out;
    animation-iteration-count: infinite;
    path {
      fill: colorFromRgb(primary-main);
    }
  }
}

@keyframes bounce {
  0% {
    opacity: 1;
    scale: 1;
    transform: translateY(0);
  }

  50% {
    opacity: 0.4;
    scale: 1;
    transform: translateY(10px);
    filter: hue-rotate(20deg);
  }
  100% {
    opacity: 1;
    scale: 1;
    transform: translateY(0);
  }
}
