@import "styles/functions.scss";
@import "styles/mixins.scss";
@import "./styles.module.scss";

/* #region childItems*/

.childItem {
  padding: 0px getSelectedItemLeftPadding() 10px;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  @include disable-select;
}
.itemIcon {
  padding-bottom: 1px;
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
.selectedItem {
  @include selectedItem;
}
/* #endregion */
