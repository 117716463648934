@import "styles/functions.scss";

.ruler {
  position: relative;
  border-radius: 12px;
  padding: 20px;
  display: flex;

  font-weight: 500;
  font-size: 16px;
  color: colorFromRgb(swamp);

  height: 82px;
  min-height: 82px;
  min-width: 225px;

  justify-content: space-between;

  &__numberPlace {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: center;
    gap: 6px;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 30px;
  }
}
