@import "styles/functions.scss";
@import "styles/mixins.scss";

.container {
  min-height: 21rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: colorFromRgb(secondary-background);
  border: 0.125rem dashed colorFromRgb(primary-main);
  border-radius: 0.635rem;
  transition: background time(default);
  position: relative;
  padding: 0 1.5rem 1rem;

  &__dragBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &.dragging {
    background-color: colorFromRgba(primary-main, 0.6);
  }
  &__image {
    max-width: 100%;
    max-height: 100%;
    margin: 1.5rem 0 1rem 0;
    & path:nth-child(3),
    :nth-child(11) {
      fill: colorFromRgb(primary-main);
    }
    &.dragging {
      animation: imageAnimation 4000ms;
      animation-iteration-count: infinite;
    }

    &[data-variant="dense"] {
      width: 6rem !important;
      height: 6rem !important;
    }
  }
  &__title {
    color: colorFromRgb(secondary-main);
    line-height: 30px;
    font-weight: 700;
    transition: color time(default);
    text-align: center;

    &.dragging {
      color: colorFromRgb(primary-contrast-text);
    }

    &[data-variant="dense"] {
      font-weight: 600 !important;
      font-size: 20px !important;
      margin: 0;
    }
  }
  &__subtitle {
    color: colorFromRgba(gray-text, 80);
    font-size: 1.125rem;
    line-height: 27px;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    transition: color time(default);

    &.dragging {
      opacity: 0;
    }
  }
  &__button {
    transition: color time(default);
    z-index: 2;
    font-weight: 600;
    &.dragging {
      opacity: 0;
    }
  }
  &__error {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem 1.5rem;
    justify-content: center;
    font-weight: 700;
    background-color: colorFromRgba(gray-text, 10);
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    color: colorFromRgb(danger);
    cursor: pointer;
    margin-top: 1.5rem;
    z-index: 2;
    &__icon {
      @include margin-left(1.5rem);
      color: colorFromRgba(danger, 0.8);
    }
  }
}
@keyframes imageAnimation {
  0% {
    transform: scale(0.9) rotate(10deg);
  }
  5% {
    transform: scale(1.2) rotate(-10deg);
  }
  10% {
    transform: scale(1.3) rotate(10deg);
  }
  15% {
    transform: scale(1) rotate(0deg);
  }
}

.dense {
  min-height: 4rem;
  flex-direction: column !important;
  padding: 0 1.5rem 0 1rem !important;

  @media (min-width: 1200px) {
    flex-direction: row !important;
    flex-wrap: wrap;
  }
}

.denseButton {
  @media (min-width: 1200px) {
    @include margin-left(auto);
  }

  @media (max-width: 1200px) {
    margin: 2rem 0;
  }
}

.fileListContainer {
  width: 100%;
  margin-top: 1.5rem;
}
