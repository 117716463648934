@import "styles/functions.scss";
@import "styles/mixins.scss";

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  @media (max-width: 992px) {
    max-height: 100%;
  }
}

.guestContainer {
  @media (max-width: 992px) {
    max-height: 100%;
  }
}

.column {
  display: flex;
  flex-direction: column;
  @include margin-left(0);
  @include margin-right(60px);
  background: colorFromRgba(gray-text, 20);
  border-radius: 0 5px 5px 5px;
  padding: 5px 14px 10px;
  gap: 8px;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: colorFromRgb(primary-main);
}

.role {
  font-weight: 600;
  font-size: 14px;
  color: colorFromRgb(gray-text);
}

.message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 10px;

  &__text {
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
    max-width: 200px;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__time {
    flex: 0 0 auto;
    justify-self: flex-end;
    font-weight: 500;
    font-size: 14px;
    color: colorFromRgb(gray-text);
  }

  &__other_back {
    background-color: colorFromRgba(primary-main, 20);
    align-self: flex-end;
    @include margin-left(60px);
    @include margin-right(0);
    border-radius: 5px 0 5px 5px;
  }

  &__other_color {
    color: colorFromRgb(primary-main);
  }
}

.messageComponent {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  //height: calc(100vh - 120px);
  flex-grow: 1;
  max-height: calc(100vh - 110px);

  @media (max-width: 992px) {
    max-height: calc(100% - 110px);
  }

  //@media (max-width: 992px) {
  //  max-height: calc(100vh - 240px);
  //  height: calc(100vh - 240px);
  //}

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

    background: colorFromRgb(secondary-background);

    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: colorFromRgba(gray-text, 30);
  }

  &__btnPlace {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__btn {
    font-weight: 400;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    gap: 10px;
    background: transparent;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__btn_selected {
    background-color: colorFromRgb(primary-main);
    color: colorFromRgb(light-text);
  }

  &__icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  &__place {
    background: colorFromRgba(gray-text, 10);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-grow: 1;
    //height: calc(100vh - 90px);
    width: 100%;
    overflow-y: auto;

    /* @media (max-width: 992px) {
      height: calc(100vh - 160px);
    }*/
  }

  &__messagesPlace {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
  }

  &__inputGroup {
    display: flex;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    gap: 10px;
    background: colorFromRgba(gray-text, 10);
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    color: colorFromRgb(gray-text);
    width: 100%;

    svg {
      path {
        stroke: colorFromRgb(gray-text);
      }
    }
  }

  &__input {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    @include margin-left(-10px);
    padding: 5px 0;

    textarea {
      resize: none !important;
      background-color: transparent;
      border: none !important;
      outline: none !important;
      font-weight: 500;
      font-size: 18px;
      color: colorFromRgb(gray-text);

      &:focus {
        border: none !important;
        outline: none !important;
      }
    }
  }

  &__inputPlace {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    gap: 10px;
    height: 90px;

    background: colorFromRgb(secondary-background);
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: colorFromRgba(gray-text, 30);
  }

  &__send {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
    background: colorFromRgb(primary-main);
    border-radius: 5px;
    width: 50px;
    height: 40px;

    svg {
      width: 20px;

      path {
        fill: colorFromRgb(light-text);
      }
    }
  }
}

.height1 {
  height: 90px;
}
.height2 {
  height: 110px;
}
.height3 {
  height: 130px;
}
.height4 {
  height: 150px;
}
.height5 {
  height: 180px;
}

.h1 {
  height: calc(100vh - 100px);
  @media (max-width: 992px) {
    height: calc(100vh - 330px);
  }
}

.h2 {
  height: calc(100vh - 110px);
  @media (max-width: 992px) {
    height: calc(100vh - 350px);
  }
}

.h3 {
  height: calc(100vh - 130px);
  @media (max-width: 992px) {
    height: calc(100vh - 370px);
  }
}

.h4 {
  height: calc(100vh - 150px);
  @media (max-width: 992px) {
    height: calc(100vh - 390px);
  }
}
.h5 {
  height: calc(100vh - 180px);
  @media (max-width: 992px) {
    height: calc(100vh - 410px);
  }
}

.h1Guest {
  @media (max-width: 992px) {
    height: calc(100vh - 260px);
  }
}

.h2Guest {
  @media (max-width: 992px) {
    height: calc(100vh - 280px);
  }
}

.h3Guest {
  @media (max-width: 992px) {
    height: calc(100vh - 300px);
  }
}

.h4Guest {
  @media (max-width: 992px) {
    height: calc(100vh - 320px);
  }
}
.h5Guest {
  @media (max-width: 992px) {
    height: calc(100vh - 340px);
  }
}

.messageComponentGuest {
  @media (max-width: 992px) {
    max-height: calc(100% - 110px);
  }
}
