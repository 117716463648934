@import "styles/functions.scss";

.detailCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  color: colorFromRgb(secondar-main);
  width: 100%;
  min-width: 250px;

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &__middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    color: colorFromRgb(secondary-main);
  }

  &__sub {
    font-weight: 700;
    font-size: 16px;
    color: colorFromRgb(gray-text);
    display: flex;
    gap: 5px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    color: colorFromRgb(primary-main);
  }
}
