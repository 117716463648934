@import "styles/functions.scss";

.vitalSign {
  direction: ltr;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  // min-width: 1054px;
  flex-wrap: wrap;
  overflow-x: auto;
  background-color: colorFromRgb(secondary-background);
  gap: 10px;
  break-inside: avoid;

  @media (max-width: 1760px) {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
  }

  &__cardsPlace {
    flex: 1 1 auto;
    display: grid;
    gap: 20px;
    padding: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: auto;
    // justify-items: center;
  }

  &__rightPart {
    position: relative;
    width: 570px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: flex-start;
    gap: 20px;
    z-index: 1;
    @media (max-width: 1760px) {
      width: 100%;
    }
  }

  &__bluePart {
    @media (max-width: 1760px) {
      width: 100%;
    }
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 463px;
    background-color: colorFromRgb(primary-main);
    border-radius: 5px;
    z-index: -1;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__human {
    @media (max-width: 1760px) {
      display: none;
    }
  }

  &__rightInside {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1760px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 20px;
    }
  }

  &__status {
    @media (max-width: 1760px) {
      flex-grow: 1;
    }
    @media (min-width: 1760px) {
      width: 283px;
    }
  }

  &__row {
    @media (max-width: 1760px) {
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
    }
  }

  &__mCards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1760px) {
      flex-grow: 1;
      gap: 20px;
    }
  }

  &__infoPlace {
    display: flex;
    flex-direction: column;
    width: 283px;
    @media (max-width: 1760px) {
      width: unset;
      flex-grow: 1;
      flex-direction: row;
      justify-content: space-around;
      gap: 20px;
      flex-wrap: wrap;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    color: colorFromRgb(light-text);
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    color: colorFromRgb(light-text);
  }
}
