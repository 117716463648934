@import "styles/functions.scss";
@import "styles/mixins.scss";

$numberWidth: 30px;
$numberMargin: 20px;

@mixin text {
  color: colorFromRgb(gray-text);
  @include disable-select;
  text-align: start;
  word-wrap: break-word;
}
.container {
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  background-color: colorFromRgb(secondary-background);
  gap: 20px;
  display: flex;
  flex-direction: column;
  min-height: 80px;
}
.card {
  display: flex;
  // align-items: center;
  // width: 100%;
  gap: 15px 12px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.cardTitle {
  flex: 0 0 auto;
  flex-grow: 1;
  font-weight: 700;
  font-size: 16px;
  color: colorFromRgb(secondary-main);
  align-self: center;
  @include disable-select;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.titleDetail {
  font-weight: 600;
  font-size: 13px;
  @include text();
}
.columnLabel {
  font-weight: 600;
  color: colorFromRgb(secondary-main);
  @include margin-right(5px);
  text-align: center;
  @include disable-select;
}

.columnValue {
  font-weight: 500;

  @include text();
}

.numberContainer {
  flex: 0 0 auto;
  background-color: colorFromRgb(primary-main);
  width: $numberWidth;
  height: $numberWidth;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 11px;
  @include margin-right(20px);
  border-radius: 5px;
  @include disable-select;
  margin-top: 4px;
}

.numberLabel {
  flex: 0 0 auto;
  color: colorFromRgb(light-text);
  font-weight: 900;
  text-align: center;
  text-align: start;
}

.deleteIcon {
  svg {
    stroke: colorFromRgb(danger) !important;
  }
}

.updateIcon {
  svg {
    stroke: colorFromRgb(warning) !important;
  }
}

.addIcon {
  svg {
    stroke: colorFromRgb(success) !important;
  }
}
.loadingSpan {
  justify-self: center;
  align-self: center;
}
.head {
  display: flex;
  @include margin-right(auto);
  align-items: self-start;
  height: fit-content;
  @media (min-width: 768px) {
    @include margin-right(10px);
    min-width: 230px;
  }
}
.titleContainer {
  align-self: center;
  overflow: hidden;
}
.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px 20px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 767px) {
    @include padding-left(calc($numberMargin + $numberWidth));
  }
}

.action {
  display: flex;
  @media (max-width: 767px) {
    margin: 0 auto;
    // padding: 0 20px;
    // justify-content: center;
    // border-top: 1px dashed black;
    // width: 100%;
  }
}
