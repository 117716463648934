@import "styles/functions.scss";

.topBar {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 25px 30px;
  gap: 8px;
  border-bottom: 1px solid colorFromRgba(gray-text, 30);
  width: 100%;
  height: 80px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  background-color: colorFromRgb(secondary-background);

  @media (max-width: 830px) {
    padding: 15px 20px;
  }

  @media (max-width: 500px) {
    padding: 8px 10px;
  }

  &__start {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    path {
      fill: colorFromRgb(primary-main);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    @media (max-width: 830px) {
      gap: 2px;
    }
  }

  &__rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    @media (max-width: 500px) {
      gap: 6px;
    }
  }

  &__homeIcon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 12px;
    cursor: pointer;

    background: colorFromRgba(gray-text, 10);
    border: 1px solid colorFromRgba(gray-text, 80);
    border-radius: 5px;
    svg {
      path {
        fill: colorFromRgb(secondary-main);
      }
    }
    @media (max-width: 830px) {
      width: 30px;
      height: 30px;

      svg {
        width: 80%;
      }
    }
  }

  &__time {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 15px;
    gap: 12px;

    background: colorFromRgba(gray-text, 10);
    border: 1px solid colorFromRgba(gray-text, 80);
    border-radius: 5px;
    height: 40px;

    font-weight: 700;
    font-size: 18px;
    color: colorFromRgb(secondary-main);

    @media (max-width: 830px) {
      padding: 10px 12px;
      font-size: 14px;
      gap: 8px;
      height: 30px;
    }

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    color: colorFromRgb(secondary-main);

    @media (max-width: 830px) {
      font-size: 14px;
    }

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }

  &__subTitle {
    font-weight: 500;
    font-size: 14px;
    color: colorFromRgb(gray-text);

    @media (max-width: 830px) {
      font-size: 11px;
    }

    @media (max-width: 500px) {
      font-size: 8px;
    }
  }
}

.topBarClose {
  height: 0;
  padding: 0;
}

.logo {
  height: 50px;
}
