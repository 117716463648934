@import "styles/functions.scss";
@import "styles/mixins.scss";

.errorIcon {
  @include margin-left(space(mg-between));

  &[data-variant="bold"] > path {
    fill: colorFromRgb(danger);
  }

  &[data-variant="linear"] > path {
    stroke: colorFromRgb(danger);
  }
}
