@import "styles/functions.scss";
@import "styles/mixins.scss";
@import "./styles.module.scss";

/* #region side menu button*/
.sideMenuButton {
  @media (max-width: getScreenThreshold()) {
    display: none !important;
  }
}

/* #endregion */
