@import "styles/functions.scss";
@import "styles/mixins.scss";

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4px;
  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }

  .checkbox {
    @include disable-select;
    display: flex;
    cursor: pointer;
    .checkboxSquare {
      &[data-checked="false"] {
        border-color: colorFromRgb(dusty-gray);
      }
    }

    & p {
      @include margin-left(8px);
      color: colorFromRgb(dusty-gray);
    }
  }

  &__title {
    cursor: pointer;
  }
}

.radioList {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  color: colorFromRgb(secondary-main);

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & p {
      margin-bottom: 0;
      @include margin-left(8px);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
