@import "styles/functions.scss";
@import "styles/mixins.scss";

.modalRoot {
  z-index: -99999;
  display: none;
  &[data-open="true"] {
    z-index: 99999;
    display: block;
  }
}

.modal {
  background-color: rgba(#000000, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  @include flex-row;
  @include flex-center;
  transition: time(default);
  opacity: 0;
  z-index: -99999;

  &[data-backdrop="true"] {
    background-color: rgba(#000000, 0.4);
    transition: time(default);
  }

  &[data-open="true"] {
    transition: time(default) opacity;
    opacity: 1;
  }

  &[data-local-open="true"] {
    z-index: 99999;
  }
}

.modal__body {
  background-color: colorFromRgb(secondary-background);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  min-width: 300px;
  margin: 20px;
  // max-width: 600px;
  max-height: 93vh;

  &[data-draggable="true"] {
    transform: translate(-50%, -50%);
  }
  @media (max-width: 767px) {
    margin: 0;
    margin-top: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 25px;
  }
}

.modal__content {
  padding: 20px 25px;
  overflow-y: auto;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px 0px;

  & path {
    stroke: colorFromRgb(secondary-main) !important;
  }

  & button {
    padding: 0;
    display: flex !important;
    justify-content: flex-end;
    min-height: 0 !important;
  }

  .modal__title {
    color: colorFromRgb(secondary-main);
    font-size: 18px;
    font-weight: 700;
  }
}
