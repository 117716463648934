@import "styles/functions.scss";

.box {
  position: relative;
  display: flex;
  width: fit-content;

  .label {
    position: absolute;
    z-index: 9999999;
    left: 50%;
    bottom: -13px;
    transform: translate(-50%, 100%);
    background-color: colorFromRgb(swamp);
    color: colorFromRgb(light-text);
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden;
    cursor: default;
    user-select: none;
    width: 0;
  }

  .right {
    transform: translate(-80%, 100%);
  }

  .icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: -3px;
    left: 50%;

    svg {
      height: 20px;
      path {
        fill: colorFromRgb(swamp);
      }
    }
  }

  &:hover .label {
    opacity: 1;
    visibility: visible;
    width: unset;
  }
  .rightIcon {
    left: 80%;
  }
}
