@import "styles/functions.scss";
@import "styles/mixins.scss";

.medical {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__inside {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &__boxes {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: colorFromRgb(secondary-main);
  }

  &__btn {
    width: 100%;
    max-width: unset;
  }

  &__result {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  &__resultText {
    font-weight: 600;
    font-size: 15px;
    color: colorFromRgb(primary-main);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    svg {
      path {
        stroke: colorFromRgb(primary-main);
      }
    }
  }

  &__title {
    align-self: flex-start;
    font-weight: 600;
    font-size: 18px;
    color: colorFromRgb(secondary-main);
  }

  &__pastData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: colorFromRgb(gray-text);
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }
}

.item {
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    gap: 10px;
    width: 100%;

    height: 68px;

    background: colorFromRgb(secondary-background);
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: colorFromRgba(gray-text, 30);
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }

  &__icon {
    transition: all 0.3s ease-in-out;

    svg {
      path {
        stroke: colorFromRgb(secondary-main);
      }
    }
  }

  &__content {
    display: flex;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }

  &__contentInside {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    gap: 20px;
    background: colorFromRgba(gray-text, 10);
  }

  &__place {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__refresh {
    background-color: colorFromRgb(primary-main);
    border-radius: 10px;
    padding: 6px 10px;
    margin-bottom: 6px;
    color: colorFromRgb(light-text);
    svg {
      path {
        stroke: colorFromRgb(light-text);
      }
    }

    &:hover {
      background-color: colorFromRgba(primary-main, 80);
    }
  }
}

.rotate {
  transform: rotateZ(90deg);
}
.audioContainer {
  width: 90%;
  z-index: 90;
}

.audioPlayer {
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  height: 51px;
  color: colorFromRgb(secondary-main);

  path {
    fill: colorFromRgb(secondary-main);
  }

  display: flex;
  background: colorFromRgb(secondary-background);
  border-radius: 8px;
  padding: 0 15px;
  align-items: center;

  &__texts {
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
  }

  &__controls {
    display: flex;
    gap: 10px;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    flex: 0 0 auto;
    background: transparent;
    border: none;
    outline: none;
    width: 24px;

    svg {
      [data-variant="bold"] > path {
        fill: colorFromRgb(secondary-main);
      }

      [data-variant="linear"] > path {
        stroke: colorFromRgb(secondary-main);
      }

      rect {
        fill: colorFromRgb(secondary-main);
      }

      circle {
        fill: colorFromRgb(secondary-main);
      }
    }
  }

  &__timeline {
    -webkit-appearance: none;
    height: 6px;
    width: 100%;
    background-color: colorFromRgba(gray-text, 30);
    border-radius: 30px;
    background-size: 0 100%;
    background-image: linear-gradient(#00171f, #00171f);
    background-repeat: no-repeat;
    @include margin-right(var(--space));
  }

  &__timeline::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: transparent;
  }

  &__timeline::-moz-range-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: transparent;
  }

  &__timeline::-ms-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: transparent;
  }

  &__timeline::-webkit-slider-thumb:hover {
    background-color: transparent;
  }

  &__timeline:hover::-webkit-slider-thumb {
    opacity: 1;
  }

  &__timeline::-moz-range-thumb:hover {
    background-color: transparent;
  }

  &__timeline:hover::-moz-range-thumb {
    opacity: 1;
  }

  &__timeline::-ms-thumb:hover {
    background-color: transparent;
  }

  &__timeline:hover::-ms-thumb {
    opacity: 1;
  }

  &__timeline::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &__timeline::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &__timeline::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &__shrink {
    flex: 0 0 auto;

    svg {
      [data-variant="bold"] > path {
        fill: colorFromRgb(secondary-main);
      }

      [data-variant="linear"] > path {
        stroke: colorFromRgb(secondary-main);
      }

      [data-variant="bold"] > rect {
        color: colorFromRgb(secondary-main);
      }

      [data-variant="linear"] > rect {
        color: colorFromRgb(secondary-main);
      }

      circle {
        fill: colorFromRgb(secondary-main);
      }
    }
  }
}

.gray {
  background: colorFromRgba(gray-text, 10) !important;
}
