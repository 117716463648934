@import "styles/functions.scss";
@import "styles/mixins.scss";

/*------------page sizes------------*/
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
/*----------variables----------*/
$defaultTime: 200ms;
$textTime: calc($defaultTime/2);
$sidebarMinWidth: 100px;
$sidebarPadding: 20px;
$iconSize: 20px;
$sidebarWidth: 378px;
$profileImageSize: 40px;
$selectedItemLeftPadding: 10px;
$screenThreshold: $lg;
$logoutBtnSize: 45px;
/*--------------------------*/

$light-bg: #fff;
$dark-bg: #19202f;
$light-textColor: #bcbcbc;
$dark-textColor: #d1dae7;
$light-selectedTextColor: #334155;
$dark-selectedTextColor: #ffffff;
$light-selectedItem-bg: #eaf6fc;
$dark-selectedItem-bg: #1b2e41;

$dusty-gray-light: rgba(#989898, 0.2);

@function getSidebarWidth() {
  @return $sidebarWidth;
}
@function getScreenThreshold() {
  @return $screenThreshold;
}
@function getSidebarMinWidth() {
  @return $sidebarMinWidth;
}
@function getSidebarPadding() {
  @return $sidebarPadding;
}
@function getSelectedItemLeftPadding() {
  @return $selectedItemLeftPadding;
}
@function getLight-selectedTextColor() {
  @return $light-selectedTextColor;
}

@mixin hideInSmallScreen {
  @media (max-width: $screenThreshold) {
    display: none;
  }
}

@mixin text {
  min-width: 0;
  opacity: 1;
  visibility: visible;
  transition: all time(default);
  white-space: nowrap;
  overflow-x: hidden;
}
@mixin selectedItem {
  color: colorFromRgb(primary-main);
  font-weight: 600 !important;
  & .itemIcon path {
    fill: colorFromRgb(primary-main) !important;
  }
  & a {
    color: getLight-selectedTextColor();
  }
}
@mixin center {
  display: flex;
  align-items: center;
}
@mixin hideText {
  opacity: 0 !important;
  visibility: hidden !important;
  height: 0;
  transition: all 100ms ease-out;
  width: 0;
}
