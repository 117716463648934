@import "styles/functions.scss";
@import "styles/mixins.scss";

.cameraControl {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__buttonsPlace {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
    background-color: colorFromRgb(seconday-background);
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: colorFromRgba(gray-text, 30);
  }

  &__btn {
    @include disable-select;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
  }

  &__btn_selected {
    background-color: colorFromRgb(primary-main);
    color: colorFromRgb(light-text);
  }

  &__outCirc {
    flex: 0 0 auto;
    position: relative;
    width: 278px;
    height: 278px;
    border-radius: 5000px;
    background-color: colorFromRgb(link-water);
    border: 4px solid colorFromRgb(light-text);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__inCirc {
    width: 122px;
    height: 122px;
    border-radius: 5000px;
    background-color: colorFromRgb(link-water);
    border: 4px solid colorFromRgb(light-text);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    path {
      fill: colorFromRgb(primary-main);
    }

    cursor: pointer;
  }

  &__inside {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100%;
  }

  &__h {
    height: 200px;
  }

  &__reqInside {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__main {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
    height: 400px;
  }

  &__up {
    position: absolute;
    left: 50%;
    top: 15%;
    transform: translate(-50%, -50%);
  }

  &__left {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__right {
    position: absolute;
    left: 85%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__down {
    position: absolute;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);
  }

  &__capture {
    cursor: pointer;
  }

  &__fullW {
    width: 80%;
    height: 40px;
    flex: 0 0 auto;
  }

  &__request {
    align-self: flex-start;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: colorFromRgba(gray-text, 30);
    padding-bottom: 15px;
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    color: colorFromRgb(gray-text);
  }
}

.item {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    gap: 10px;

    width: 100%;
    height: 68px;

    background: colorFromRgb(secondary-background);
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: colorFromRgba(gray-text, 30);
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }

  &__icon {
    transition: all 0.3s ease-in-out;

    svg {
      path {
        stroke: colorFromRgb(secondary-main);
      }
    }
  }

  &__content {
    display: flex;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }

  &__contentInside {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    gap: 20px;
  }
}

.rotate {
  transform: rotateZ(90deg);
}
