@import "styles/functions.scss";

.icon {
  path[stroke="#295DE1"] {
    stroke: colorFromRgb(primary-main) !important;
  }

  rect[fill="#295DE1"] {
    fill: colorFromRgb(primary-main) !important;
  }

  path[stroke="#00171F"] {
    stroke: colorFromRgb(secondary-main) !important;
  }

  path[fill="#00171F"] {
    fill: colorFromRgb(primary-main) !important;
  }

  rect[fill="#00171F"] {
    fill: colorFromRgb(secondary-main) !important;
  }

  circle[fill="#00171F"] {
    fill: colorFromRgb(secondary-main) !important;
  }
}

.logo {
  path[stroke="#295DE1"] {
    stroke: colorFromRgb(primary-main) !important;
  }

  path[fill="#295DE1"] {
    fill: colorFromRgb(primary-main) !important;
  }

  rect[fill="#295DE1"] {
    fill: colorFromRgb(primary-main) !important;
  }
  path[stroke="#00171F"] {
    stroke: colorFromRgb(secondary-main) !important;
  }

  path[fill="#00171F"] {
    fill: colorFromRgb(secondary-main) !important;
  }
  rect[fill="#00171F"] {
    fill: colorFromRgb(secondary-main) !important;
  }

  path[stroke="#989898"] {
    stroke: colorFromRgb(gray-text) !important;
  }

  path[fill="#989898"] {
    fill: colorFromRgb(gray-text) !important;
  }
  rect[fill="#989898"] {
    fill: colorFromRgb(gray-text) !important;
  }
}

.serverError {
  path[stroke="#295DE1"] {
    stroke: colorFromRgb(primary-main) !important;
  }

  path[fill="#295DE1"] {
    fill: colorFromRgb(primary-main) !important;
  }

  rect[fill="#295DE1"] {
    fill: colorFromRgb(primary-main) !important;
  }
}

.forbid {
  path[stroke="#295DE1"] {
    stroke: colorFromRgb(primary-main) !important;
  }

  path[fill="#295DE1"] {
    fill: colorFromRgb(primary-main) !important;
  }

  rect[fill="#295DE1"] {
    fill: colorFromRgb(primary-main) !important;
  }
  path[stroke="#989898"] {
    stroke: colorFromRgb(gray-text) !important;
  }

  path[fill="#989898"] {
    fill: colorFromRgb(gray-text) !important;
  }
  rect[fill="#989898"] {
    fill: colorFromRgb(gray-text) !important;
  }
}

.unAvailable {
  path[stroke="#295DE1"] {
    stroke: colorFromRgb(primary-main) !important;
  }

  path[fill="#295DE1"] {
    fill: colorFromRgb(primary-main) !important;
  }

  rect[fill="#295DE1"] {
    fill: colorFromRgb(primary-main) !important;
  }

  path[fill="#00171F"] {
    fill: colorFromRgb(secondary-main) !important;
  }
  rect[fill="#00171F"] {
    fill: colorFromRgb(secondary-main) !important;
  }

  path[stroke="#989898"] {
    stroke: colorFromRgb(gray-text) !important;
  }

  path[fill="#989898"] {
    fill: colorFromRgb(gray-text) !important;
  }
  rect[fill="#989898"] {
    fill: colorFromRgb(gray-text) !important;
  }
}
