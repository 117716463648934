@import "styles/functions.scss";
@import "styles/mixins.scss";
// @import "./styles.module.scss";

.languageDropdown {
  margin-top: 7px;
  margin-left: 7px;
}

.avatar_setting__icon {
  position: absolute;
  top: 53px;
  // left: 56px;
  @include left(56px);
  z-index: 500;
  border-radius: 50% !important;
  border: 2px solid colorFromRgb(light-text) !important;
  background-color: colorFromRgb(primary-main) !important;
  padding: 10px !important;

  & svg {
    width: 16px !important;
    height: 16px !important;
    @include margin-right(0 !important);
  }

  & path {
    fill: colorFromRgb(light-text);
  }
}

.avatar_setting_icon__min {
  // position: absolute;

  top: 35px;
  // left: 35px;
  @include left(35px);

  & svg {
    width: 13px !important;
    height: 13px !important;
  }
}
.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.avatar__dropdownItem {
  padding: 10px !important;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px !important;

  display: flex;
  align-items: center;
  justify-content: space-between;
  & svg {
    width: 24px;
    height: 24px;
    stroke: colorFromRgb(secondary-main);
  }

  & .avatar__dropdownNestedIcon {
    @include margin-left(20px);
    & svg {
      @include rotate(0);
      width: 12px !important;
      height: 12px !important;
      stroke: colorFromRgb(secondary-main);
    }
  }
}
.avatarSettingDropdownMenu {
  margin-top: 7px;
}

.lang {
  font-size: 13px;
  font-weight: 500;
}

.languagePlace {
  margin: 0px -7px;
  border-radius: 5px;
  width: 200px;
  padding: 5px 15px;
}

.selectedLang {
  background-color: colorFromRgba(gray-text, 10);
}

.langIcon {
  svg {
    stroke: colorFromRgb(primary-main) !important;
  }
}
