@mixin disable-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@mixin sizes {
  &[data-size="small"] {
    max-height: space(height-small);
    min-height: space(height-small);
  }

  &[data-size="normal"] {
    max-height: space(height-normal);
    min-height: space(height-normal);
  }

  &[data-size="big"] {
    max-height: space(height-big);
    min-height: space(height-big);
  }
}

@mixin animateWith($name) {
  animation-duration: 0.5s;
  animation-name: $name;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin simple-button {
  outline: none !important;
  border: none;
  background-color: transparent;
}

@mixin flipProperty($ltr-property, $rtl-property, $value) {
  [dir="ltr"] & {
    #{$ltr-property}: $value;
  }

  [dir="rtl"] & {
    #{$rtl-property}: $value;
  }
}
@mixin left($value) {
  @include flipProperty("left", "right", $value);
}
@mixin right($value) {
  @include flipProperty("right", "left", $value);
}

@mixin padding-left($value) {
  @include flipProperty("padding-left", "padding-right", $value);
}
@mixin padding-right($value) {
  @include flipProperty("padding-right", "padding-left", $value);
}

@mixin margin-left($value) {
  @include flipProperty("margin-left", "margin-right", $value);
}
@mixin margin-right($value) {
  @include flipProperty("margin-right", "margin-left", $value);
}

@mixin rotate($degree) {
  [dir="ltr"] & {
    transform: rotate(#{$degree}deg);
  }

  [dir="rtl"] & {
    $deg: calc($degree + 180);
    transform: rotate(#{$deg}deg);
  }
}
