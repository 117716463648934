.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  row-gap: 30px;
  column-gap: 20px;
}
.gridFlowDense {
  //grid-auto-flow: dense;
}
.bigColumn {
  /* If the screen is larger than 605px */
  @media (min-width: 605px) {
    grid-column: auto/span 2;
  }
}
.startFromNextRow {
  grid-column-start: 1;
}
.fullWidthColumn {
  margin: 30px 0;
  width: 100%;
}
.gap20 {
  gap: 20px;
}
.gridButton {
  margin-top: 36px;
  @media screen and (max-width: 587px) {
    margin-top: 0;
  }
}
