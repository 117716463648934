@import "styles/functions.scss";

.healthRecord {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  &__actions {
    display: flex;
    justify-self: flex-start;
    justify-items: flex-start;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__sep {
    width: 1px;
    background-color: colorFromRgba(gray-text, 40);
  }

  &__print {
    background-color: colorFromRgb(secondary-background);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    path {
      fill: colorFromRgb(primary-main);
    }
  }

  &__signImage {
    width: 300px;
  }

  &__tabs {
    align-self: flex-start;
  }

  &__row {
    display: flex;
    gap: 20px;
  }
}

.modal {
  display: flex;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  gap: 20px;
  max-height: 80vh;

  &__back {
    background-color: colorFromRgba(gray-text, 10) !important;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    color: colorFromRgb(gray-text);
  }

  &__bigText {
    font-weight: 700;
    font-size: 16px;
    color: colorFromRgb(gray-text);
  }

  &__bold {
    color: colorFromRgb(secondary-main);
  }

  &__smallPrimary {
    font-weight: 500;
    font-size: 14px;
    color: colorFromRgb(primary-main);
  }

  &__sig {
    width: 300px;
    break-inside: avoid;
  }

  &__btn {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-self: flex-end;

    @media print {
      display: none !important;
    }
  }
}

.noScroll {
  overflow-y: unset;
}
