@import "styles/functions.scss";
@import "styles/mixins.scss";
@import "./styles.module.scss";

/* #region footer*/
.sidebarFooter {
  border-top: 1px solid #bcbcbc80;
  margin-top: auto;
  padding: getSidebarPadding();
  @media (max-width: getScreenThreshold()) {
    border-bottom: 1px solid #bcbcbc80;
    padding-bottom: calc(getSidebarPadding() / 2);
  }
}
/* #endregion */
