@import "styles/functions.scss";
@import "styles/mixins.scss";

.summary {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  &__topLinks {
    font-weight: 600;
    font-size: 18px;
    color: colorFromRgb(gray-text);
  }

  &__topSelected {
    color: colorFromRgb(primary-main);
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__ans {
    font-weight: 700;
    font-size: 16px;
    color: colorFromRgb(gray-text);
  }

  &__sep {
    padding-bottom: 30px;
    border-bottom: 1px solid colorFromRgba(gray-text, 20);
  }

  &__tab {
    justify-self: flex-start;
  }

  &__row {
    display: flex;
  }

  &__gb {
    gap: 20px;
  }

  &__none {
    display: none;
  }

  &__gx {
    gap: 30px;
  }

  &__gxx {
    gap: 40px;
  }

  &__gm {
    gap: 10px;
  }

  &__je {
    justify-content: flex-end;
  }

  &__js {
    justify-content: flex-start;
  }

  &__jc {
    justify-content: center;
  }

  &__as {
    align-items: flex-start;
  }

  &__ac {
    align-items: center;
  }

  &__ae {
    align-items: flex-end;
  }

  &__ts {
    margin-top: 5px;
  }

  &__tm {
    margin-top: 10px;
  }

  &__tb {
    margin-top: 20px;
  }

  &__tx {
    margin-top: 30px;
  }

  &__bs {
    margin-bottom: 5px;
  }

  &__bm {
    margin-bottom: 10px;
  }

  &__bb {
    margin-bottom: 20px;
  }

  &__bx {
    margin-bottom: 30px;
  }

  &__secondary {
    font-weight: 700;
    font-size: 16px;
    color: colorFromRgb(secondary-main);
  }

  &__primary {
    font-weight: 700;
    font-size: 20px;
    color: colorFromRgb(primary-main);
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 100px;
  }

  &__btn {
    align-self: flex-start;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    gap: 12px;
    height: 36px;
    background: colorFromRgba(gray-text, 10);
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    color: colorFromRgb(gray-text);
  }

  &__itemIcon {
    background-color: colorFromRgb(secondary-background);
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;

    svg {
      path {
        stroke: colorFromRgb(gray-text);
      }
    }
  }

  &__w100 {
    width: 100%;
  }

  &__w60 {
    width: 60%;
  }

  &__bold {
    font-weight: 700;
    color: colorFromRgb(secondary-main);
  }

  &__grayBack {
    background-color: colorFromRgba(gray-text, 10) !important;
  }

  &__cp {
    color: colorFromRgb(primary-main);
  }

  &__pointer {
    cursor: pointer;
  }
  &__text {
    font-weight: 500;
    font-size: 16px;
    color: colorFromRgb(gray-text);
  }
  &__card {
    [data-variant="bold"] > path {
      fill: colorFromRgb(light-text) !important;
    }

    [data-variant="linear"] > path {
      stroke: colorFromRgb(light-text) !important;
    }
  }

  &__icon {
    path {
      stroke: colorFromRgb(danger) !important;
    }
  }

  &__bigArea {
    width: 100%;
    min-height: 420px;
  }

  &__bigSecondary {
    font-weight: 700;
    font-size: 24px;
    color: colorFromRgb(secondary-main);
  }

  &__upDown {
    display: flex;
    @include margin-right(10px);
    gap: 10px;
    background: colorFromRgb(light-text);
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: colorFromRgba(gray-text, 60);
    height: 44px;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }
}
