@import "styles/mixins.scss";
@import "styles/functions.scss";

.avatar {
  @include flex-center;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  @include disable-select;
  border-radius: 50%;

  border: 2px solid colorFromRgb(light-text);
  background: #ffefdb;
  padding: 1px;
  &[data-size="small"] {
    width: 35px;
    height: 35px;
    border-width: 1px;
    font-size: small;
  }

  &[data-size="normal"] {
    width: 45px;
    height: 45px;
    border-width: 1px;
    font-size: medium;
  }

  &[data-size="big"] {
    width: 60px;
    height: 60px;
    font-size: large;
  }

  &[data-size="large"] {
    width: 86px;
    height: 86px;
    font-size: larger;
  }
  &[data-size="x-large"] {
    width: 148px;
    height: 148px;
    font-size: x-large;
  }

  &[data-size="xx-large"] {
    width: 200px;
    height: 200px;
    font-size: x-large;
  }

  &[data-size="veryLarge"] {
    width: 267px;
    height: 267px;
    border-width: 4px;
    border: 5px solid colorFromRgb(light-text);
    font-size: xx-large;
  }

  &[data-isvalid="false"] {
    .avatarContent {
      // background: linear-gradient(#dd5e89, #f7bb97);
      background: linear-gradient(#ff512f, #dd2476);
      // background: linear-gradient(#6dd5ed, #2193b0);
    }
  }

  &[data-loading="true"] {
    background-color: colorFromRgb(danger);
  }
}

.avatarContent {
  @include flex-center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  color: colorFromRgb(light-text);
  font-weight: 600;
  // font-size: 18px;
  border-radius: 50%;
}

.avatarContainer {
  position: relative;
  cursor: default;
}

.flag {
  cursor: default;
  position: absolute;
  right: -15px;
  top: 45px;
}
