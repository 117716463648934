@import "styles/functions.scss";

.paginator {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding-top: 20px;
  break-inside: avoid;

  &__row {
    display: flex;
    height: fit-content;
    overflow-x: auto;
    padding-bottom: 8px;
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    color: colorFromRgb(gray-text);
  }

  &__gap {
    gap: 10px;
  }

  &__button {
    width: 40px;
    height: 40px;
  }
}

.invisible {
  display: none;
  width: 100%;
}

.visible {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: fit-content;
  width: 100%;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  /*transition-property: width, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  animation: anim 1s ease-in-out;*/
}

.animExit {
  //animation: animExit 0.5s ease-in-out;
}

@keyframes animExit {
  0% {
    opacity: 1;
    transform: translateX(0);
    width: 100%;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
    display: none;
    width: 100%;
  }
}
@keyframes anim {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
