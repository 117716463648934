@import "styles/functions.scss";

.labelCard {
  padding: 10px 30px;
  font-weight: 500;
  font-size: 16px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow-x: auto;

  &__head {
    display: flex;
    gap: 5px;
  }

  &__bold {
    font-weight: 700;
  }

  &__secondary {
    color: colorFromRgb(secondary-main);
  }

  &__gray {
    color: colorFromRgb(gray-text);
  }

  &__danger {
    color: colorFromRgb(danger);
  }
}
