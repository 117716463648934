@import "styles/functions.scss";

.showSummaryNote {
  overflow-x: hidden;

  [data-variant="bold"] > path {
    fill: colorFromRgb(light-text);
  }

  [data-variant="linear"] > path {
    stroke: colorFromRgb(light-text);
  }

  &__title {
    color: colorFromRgb(secondary-main);
    font-weight: 700;
  }

  &__secondary {
    font-weight: 700;
    font-size: 24px;
    color: colorFromRgb(secondary-main);
  }

  &__smallGray {
    font-weight: 500;
    font-size: 14px;
    color: colorFromRgb(gray-text);
  }

  &__primaryBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 30px;
    background: colorFromRgb(primary-main);
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: colorFromRgb(light-text);
  }
}

.cards {
  [data-variant="bold"] > path {
    fill: colorFromRgb(light-text) !important;
  }

  [data-variant="linear"] > path {
    stroke: colorFromRgb(light-text) !important;
  }
}

.wrap {
  flex-wrap: wrap;
}

.colorPrimary {
  color: colorFromRgb(primary-main);
}

.none {
  display: none;
}

.grow {
  flex-grow: 1;
  width: 100%;
}

.row {
  display: flex;
  @media (max-width: 670px) {
    flex-wrap: wrap;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.w100 {
  width: 100%;
}

.gs {
  gap: 5px;
}

.gm {
  gap: 10px;
}

.gb {
  gap: 20px;
}

.gx {
  gap: 30px;
}

.gxx {
  gap: 40px;
}

.mts {
  margin-top: 5px;
}

.mtm {
  margin-top: 10px;
}

.mtb {
  margin-top: 20px;
}

.mtx {
  margin-top: 30px;
}

.pg {
  break-inside: avoid !important;
}
