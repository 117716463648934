@import "styles/functions.scss";

.vitalCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  width: auto;
  height: auto;
  max-height: 400px;

  background: colorFromRgba(gray-text, 10);
  border-radius: 5px;

  color: colorFromRgb(secondary-main);

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  &__iconPlace {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: colorFromRgb(primary-main);
    border-radius: 5px;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
  }

  &__numPlace {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &__num {
    font-weight: 400;
    font-size: 32px;
  }

  &__unit {
    font-weight: 700;
    font-size: 16px;
    color: colorFromRgb(gray-text);
  }

  &__condition {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
  }
  &__chartContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: -10px;
  }

  &__chart {
    position: relative;
    width: 100%;
  }

  &__chartBottom {
    margin-top: -6px;
    width: 94%;
    height: 20px;
    background: linear-gradient(
      colorFromRgba(primary-main, 50) 30%,
      transparent
    );
  }
}

.normal {
  background-color: colorFromRgba(success, 10);
  color: colorFromRgb(success);
}

.warning {
  background-color: colorFromRgba(warning, 10);
  color: colorFromRgb(warning);
}

.danger {
  background-color: colorFromRgba(danger, 10);
  color: colorFromRgb(danger);
}
