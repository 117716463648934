@import "styles/functions.scss";

.shareModal {
  font-size: 16px;
  color: colorFromRgb(secondary-main);
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__input {
    background-color: colorFromRgba(gray-text, 30);
    border-radius: 5px;
    border: 1px dashed colorFromRgb(gray-text);
    // width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px 10px;
    cursor: text;
    flex-grow: 1;
  }

  &__urlContainer {
    display: flex;
    gap: 7px;
  }

  &__shareViaEmailContainer {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  &__emailInput {
    width: 100%;
    span {
      text-transform: none !important;
    }
  }

  &__emailInputActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
  }

  &__copyBtn {
    min-width: 160px;
  }
}
