@import "styles/functions.scss";
@import "styles/mixins.scss";
@import "./components/styles.module.scss";

/* #region main*/
.main {
  @include padding-left($sidebarWidth);

  @media print {
    @include padding-left(0);
  }
  @media (max-width: $screenThreshold) {
    @include padding-left(0);
  }
  transition: padding time(default);
}
.main_paddingLeft {
  @include padding-left($sidebarMinWidth);
  @media (max-width: $screenThreshold) {
    @include padding-left(0);
  }
}
/* #endregion */
