@import "styles/mixins.scss";
@import "styles/functions.scss";
.timeRoot {
  display: flex;
  flex-flow: row;
  gap: 2px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: space(pd-y) 10px;
  border-radius: space(radius);
  background-color: colorFromRgb(secondary-background);
  color: colorFromRgb(gray-text);
  @include disable-select;
  @include sizes;
  & > * {
    @include disable-select;
  }
}

.numberInput {
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  width: 35px;
  color: colorFromRgb(gray-text);
  border-radius: 5px;
  transition: 200ms;
  flex-shrink: 0;

  &:focus {
    transition: 200ms;
    background-color: rgba(color(gray-text), 0.2);
    border: none;
    outline: none;
  }
}

.periodInput {
  cursor: pointer;
  margin-inline-start: 2px;
  width: 35px;
  color: rgba(color(gray-text), 0.6);
  border: none;
  caret-color: transparent;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(color(gray-text), 0.1);
  flex-shrink: 0;

  &:focus {
    transition: 200ms;
    background-color: rgba(color(gray-text), 0.3);
    border: none;
    outline: none;
  }
}

.arrow {
  cursor: pointer;
  margin: 3px;

  &[data-disabled="true"] {
    cursor: default;
    & > path {
      stroke: rgba(color(gray-text), 0.6);
    }
  }
}

.arrowsContainer {
  display: inherit;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-inline-start: 4px;
  svg path {
    stroke: colorFromRgb(gray-text);
  }
}

.arrowUp {
  transform: rotateZ(180deg);
}
