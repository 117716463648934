@import "styles/functions.scss";

.parent {
  width: 100%;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
  background-position: center;
  position: relative;
  min-height: 150px;

  &__topBar {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: -100px;
    z-index: 10;
    align-items: stretch;
    transition: all 0.3s ease-in-out;
  }

  &__sideTop {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  &__sideBottm {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    align-self: flex-end;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(40px);
    position: absolute;
    bottom: 100px;
    border-radius: 250px;
    left: 50%;
    padding: 10px 24px;
    transform: translateX(-50%);
    height: 60px;
    z-index: 999;
    svg {
      [data-variant="bold"] > path {
        fill: colorFromRgb(light-text);
      }

      [data-variant="linear"] > path {
        stroke: colorFromRgb(light-text);
      }
    }
  }

  &__ic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      width: 30px;
      height: 30px;
      path {
        fill: colorFromRgb(light-text);
      }
    }
  }

  &__centrIc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  &__zoom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  &__undo {
    svg {
      width: 30px;
      height: 30px;
      path {
        stroke: colorFromRgb(light-text);
      }
    }
  }
}

.videoContainer {
  width: 100%;
  height: 100%;
  // background-size: cover;
  // background-position: center;
  position: relative;
  background-color: black;
  video {
    object-fit: contain;
  }
}

.cover {
  video {
    object-fit: cover;
  }
}

.screenShare {
  width: 25%;
  height: 30%;
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
}

.icons {
  width: 20px;
  height: 20px;

  path {
    stroke: colorFromRgb(white);
  }
}

.iconPlace {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: colorFromRgba(gray-text, 50);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  visibility: visible;
  opacity: 1;
  backdrop-filter: blur(7.5px);

  @media (max-width: 700px) {
    width: 25px;
    height: 25px;
    svg {
      width: 14px;
    }
  }
  @media (max-height: 400px) {
    width: 25px;
    height: 25px;
    svg {
      width: 14px;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.texts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  background-color: colorFromRgba(gray-text, 50);
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 15px;

  @media (max-width: 700px) {
    padding: 3px 8px;
    font-size: 12px;
  }
  @media (max-height: 400px) {
    padding: 3px 8px;
    font-size: 12px;
  }

  color: colorFromRgb(white);
}

.danger {
  background-color: colorFromRgb(danger);
}

.primary {
  background-color: colorFromRgb(primary-main);
}

.fullH {
  height: 100%;
  border-radius: 0;
}

.minimize {
  display: none;
}

.no {
  display: none;
}

.hide {
  opacity: 0;
  visibility: visible;
  display: none;
}
