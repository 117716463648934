@import "styles/functions.scss";

.bottomBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: colorFromRgb(secondary-background);
  border-top: 1px solid colorFromRgba(gray-text, 30);
  padding: 20px 30px;
  width: 100%;

  @media (max-width: 860px) {
    flex-wrap: wrap;
  }

  @media (max-width: 714px) {
    justify-content: center;
    gap: 10px;
  }

  @media (max-width: 450px) {
    padding: 10px 12px;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 1200px) {
      gap: 10px;
    }

    @media (max-width: 714px) {
      width: 100%;
      justify-content: center;
    }

    @media (max-width: 650px) {
      flex-wrap: wrap;
    }
  }

  &__dangerButton {
    background-color: colorFromRgb(danger) !important;
    height: 45px !important;
    max-height: unset !important;

    @media (max-width: 1200px) {
      height: 35px !important;
      min-height: unset !important;

      svg {
        width: 16px !important;
      }
    }
  }

  &__refreshAnim {
    svg {
      animation: rotate 0.7s ease-in-out infinite;
      animation-direction: revert;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  :global .rc-slider {
    position: relative !important;
  }

  &__slider {
    z-index: 45345;
    height: 10px;
    background: colorFromRgba(gray-text, 20);
    border: 1px solid colorFromRgb(gray-text);
    border-radius: 32px;
    padding: 1px;

    &Track {
      background-color: colorFromRgb(primary-main);
      height: 10px;
      border-radius: 33px;
      position: relative;
      transform: translateY(-100%);
    }

    &Handler {
      background: colorFromRgb(gray-text);
      box-shadow: 0 2px 20px colorFromRgba(gray-text, 0.2);
      border-radius: 14px;
      width: 7px;
      height: 30px;

      cursor: pointer;
      transform: translate(-50%, -100%) !important;
      position: absolute;
    }

    &Percent {
      position: absolute;
      top: -45px;
      width: 35px;
      height: 30px;
      border-radius: 5px 5px 50% 50%;
      color: colorFromRgb(light-text);
      font-weight: 700;
      font-size: 14px;
      background-color: colorFromRgb(primary-main);
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s ease-in-out;
    }
  }

  &__buttons {
    height: 40px;
    max-height: 40px !important;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 18px;
    color: colorFromRgb(light-text);
    flex: 0 0 auto;

    @media (max-width: 1200px) {
      height: 26px;
      max-height: 26px !important;
      min-height: 35px !important;
      padding: 6px 20px;

      font-weight: 600;
      font-size: 12px;
    }
  }

  &__controlButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 16px;
    max-height: 40px;
    background: colorFromRgba(gray-text, 10);
    border: 1px solid colorFromRgba(gray-text, 80);
    border-radius: 5px;
    cursor: pointer;

    @media (max-width: 1200px) {
      padding: 10px;
      gap: 6px;
      max-height: 35px;
    }

    @media (max-width: 411px) {
      padding: 4px;
    }
  }

  &__screenIcon {
    height: 26px !important;

    path {
      stroke: colorFromRgb(secondary-main);
      fill: colorFromRgb(secondary-main);
    }

    @media (max-width: 1200px) {
      height: 18px !important;
    }
  }

  &__left {
    display: flex;
    gap: 5px;
  }

  &__icons {
    height: 26px !important;

    path {
      stroke: colorFromRgb(primary-main);
    }

    @media (max-width: 1200px) {
      height: 18px !important;
    }
  }

  &__dIcon {
    height: 26px !important;

    path {
      stroke: colorFromRgb(danger);
    }

    @media (max-width: 1200px) {
      height: 18px !important;
    }
  }
}

.dangerIcon {
  path {
    stroke: colorFromRgb(danger);
  }
}

.dropdown {
  padding: 0;
  background: colorFromRgb(link-water) !important;
  border: 1px solid colorFromRgba(gray-text, 80) !important;
  border-radius: 5px;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: colorFromRgb(secondary-main) !important;

  @media (max-width: 810px) {
    font-size: 14px;
  }

  @media (max-width: 610px) {
    font-size: 10px;
  }

  &__item {
    padding: 15px 20px;
    font-size: 18px !important;

    @media (max-width: 810px) {
      padding: 10px 14px;
      font-size: 14px !important;
    }

    @media (max-width: 610px) {
      padding: 8px 6px;
      font-size: 10px !important;
    }

    &:hover {
      background: colorFromRgba(primary-main, 10) !important;
      color: colorFromRgb(primary-main) !important;
    }
  }

  &__sliderItem {
    padding: 15px 20px;
    font-size: 18px !important;

    @media (max-width: 810px) {
      padding: 10px 14px;
      font-size: 14px !important;
    }

    @media (max-width: 610px) {
      padding: 8px 6px;
      font-size: 10px !important;
    }
  }

  &__title {
    padding: 15px 20px;
    font-size: 18px !important;

    @media (max-width: 810px) {
      padding: 10px 14px;
      font-size: 14px !important;
    }

    @media (max-width: 610px) {
      padding: 8px 6px;
      font-size: 10px !important;
    }
  }
}

.switch {
  height: 26px;

  @media (max-width: 1200px) {
    height: 16px;

    & div {
      height: 16px !important;
      width: 16px !important;
    }

    [data-status="on"] {
      transform: translateX(24px) !important;
    }

    [data-status="off"] {
      transform: translateX(-3px) !important;
    }
  }

  @media (max-width: 860px) {
    display: none;
  }
}

.none {
  visibility: hidden;

  @media (max-width: 714px) {
    display: none;
  }
}

.noDisplay {
  display: none;
}
