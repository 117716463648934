@import "styles/mixins.scss";

.flagContainer {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.flag {
  width: 22px;
  height: auto;
  border-radius: 2px;
  @include margin-right(8px);
  @include margin-left(10px);
}

.text {
  font-weight: 500;
}
