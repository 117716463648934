@import "styles/functions.scss";
@import "styles/mixins.scss";
@import "./styles.module.scss";

/* #region hamburgerMenu */
.hamburgerMenu {
  @media print {
    display: none !important;
  }

  position: fixed;
  top: 0;
  // left: 0;
  @include left(0);
  display: none;
  z-index: 101;
  height: space(mobile-header-height);
  align-items: center;
  padding: 30px;
  background-color: color(secondary-background);
  color: color(secondary-main);
  cursor: pointer;
  @media (max-width: getScreenThreshold()) {
    display: flex;
  }
  @include disable-select;
}
.fullWidth {
  @media (max-width: getScreenThreshold()) {
    right: 0;
  }
}
@mixin bar {
  width: 18px;
  height: 3px;
  background-color: colorFromRgb(secondary-main);
  margin: 3px 0;
  transition: all time(default);
  border-radius: 3px;
}
.bar1 {
  @include bar();
  transform-origin: top left;
}
.bar2 {
  @include bar();
}
.bar3 {
  @include bar();
  transform-origin: bottom left;
}
.change .bar1 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.hamburgerMenuText {
  // @include margin-left( 8px);
  @include margin-left(8px);
  font-size: 16px;
  font-weight: 600;
  transition: all time(default);
}
/* #endregion */
