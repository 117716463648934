@import "styles/functions.scss";

.minimize {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // padding: 25px;
  // gap: 25px;
  transform: translate(-50%, -50%);
  position: relative;
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 260px;
  // background: colorFromRgb(secondary-main);
  // box-shadow: 0 4px 20px rgba(0, 23, 31, 0.25);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1000;

  @media print {
    display: none;
  }

  &__top {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__imgContainer {
    background-color: colorFromRgb(secondary-main);
    padding: 5px;
    border-radius: 500px;
    border: 2px solid #ffefdb;
    width: 120px;
    height: 120px;
  }

  &__imgPlace {
    background-color: #ffefdb;
    border-radius: 500px;
    overflow: hidden;
  }

  &__img {
    width: 100%;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 55px;
    height: 55px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 999;
  }
}

.videoContainer {
  position: relative;
  height: 100%;
  width: 100%;

  .swapUser {
    transition: 0.3s ease-in-out;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: colorFromRgba(swamp, 25);
    opacity: 0;

    .fontStyle {
      font-weight: 600;
      font-size: 16px;
      color: colorFromRgb(light-text);
      margin: 0;
    }

    &__hide {
      opacity: 100;
    }
  }
}

.btnContainer {
  position: relative;
  bottom: 65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 10;
}

.white {
  background-color: colorFromRgb(light-text);
}

.primary {
  background-color: colorFromRgb(primary-main);

  svg {
    path {
      fill: colorFromRgb(light-text);
    }
  }
}

.danger {
  background-color: colorFromRgb(danger);

  svg {
    width: 100px !important;

    path {
      fill: colorFromRgb(light-text);
    }
  }
}
